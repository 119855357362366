import React from 'react'

import Layout from '../components/Layout'
import SEO from '../components/seo'
import Hero from '../components/Hero'
import Person from '../components/Person'

import photoMatteo from '../images/team_matteo.jpg'
import photoDario from '../images/team_dario.jpg'
import photoCecilia from '../images/team_cecilia.jpg'
import photoRoberta from '../images/team_roberta.jpg'
// import teamYou from '../images/team_you.svg'

const TeamPage = () => {
  return (
    <Layout>
      <SEO
        description="F2.net team of frontend and backend developers"
        keywords={[
          `F2.net`,
          `React`,
          `Redux`,
          `Next.js`,
          `Gatbsby`,
          `Node.js`,
          `mysql`,
          `postgresql`,
        ]}
        title="Our Team of React and Node.js Developers"
      />

      <Hero>
        <h1 className="text-3xl sm:text-5xl text-center font-black leading-tight mb-3">
          Team
        </h1>
        <p className="text-xl text-center leading-relaxed text-gray-700">
          We are a technology-focused team of skilled Front-end and Back-end
          developers
        </p>
      </Hero>

      <section className="px-6 pb-20">
        <div className="flex flex-row flex-wrap max-w-sm sm:max-w-xl lg:max-w-3xl mx-auto justify-center sm:justify-between">
          <Person
            name="Matteo Frana"
            photoUrl={photoMatteo}
            role="Founder and Lead front-end developer"
          ></Person>
          <Person
            name="Dario Ronzoni"
            photoUrl={photoDario}
            role="Founder and Lead back-end developer"
          ></Person>
          <Person
            name="Cecilia Panicali"
            photoUrl={photoCecilia}
            role="Front-end developer"
          ></Person>
          <Person
            name="Roberta Ferrari"
            photoUrl={photoRoberta}
            role="Social media manager"
          ></Person>
          {/* <Person
            isRecruit
            name="You?"
            photoUrl={teamYou}
            role="Back-end developer"
          ></Person> */}
        </div>
      </section>
    </Layout>
  )
}

export default TeamPage
