import React from 'react'
import { Link } from 'gatsby'

const Person = ({ name, photoUrl, role, isRecruit = false }) => {
  return (
    <div className="w-64 sm:w-1/2 px-12 pb-16 flex flex-col">
      <img
        alt={name}
        className="w-32 h-32 rounded-full mx-auto mb-6"
        src={photoUrl}
      />
      <p className="font-extrabold text-center">{name}</p>
      <p className="text-sm text-gray-600 text-center">{role}</p>
      {isRecruit && (
        <Link className="text-sm text-center" to="/contact">
          Contact us!
        </Link>
      )}
    </div>
  )
}

export default Person
